$bb-toolbar-height: 72px !default;

*,
:after,
:before {
  box-sizing: border-box;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

html {
  position: relative;
  min-height: 100%;
  background: #ffffff;
}

body {
  background: transparent;
  font-family: "Roboto", sans-serif;
  margin: 0;
  overflow-x: hidden;
  color: #31394d;
  font-weight: 400;
  height: 100vh;
  font-size: 1rem;
}

button,
a {
  text-decoration: none;
  cursor: pointer;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font: inherit !important;
  letter-spacing: inherit !important;
}

/*@import "assets/css/style.default.css";*/

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

a,
a:hover,
a:focus,
a:visited {
  color: #217e6e;
}

a:focus {
  color: inherit;
}

.sidebar {
  z-index: 101;
}

.fix-sidebar .sidebar {
  position: fixed;
  overflow: hidden;
}

.fix-sidebar .top-left-part {
  position: fixed;
  width: 250px;
}

.fix-sidebar .navbar-left {
  margin-left: 250px;
}

.input-group.custom-search-form {
  margin: 30px 0;
  padding: 0px 10px;
}

#side-menu {
  display: block;
  transition: 0.5 easy-out;
  padding: 0;
  margin: 0;
}

#page-wrapper {
  position: relative;
  margin: 0;
  width: 100%;
  // height: 100%; //it shows multiple scroll bar
}

.container-fluid {
  padding-left: 50px;
  padding-right: 100px;
  padding-bottom: 120px;
  padding-top: 20px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  height: auto;
  // min-height: calc(100vh - 72px); // scroll bar overlaps with toolbar
  overflow-y: auto;
  max-height: calc(100vh - 72px);
  margin-top: 72px;
  background-color: #f7fafe;

  &.mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.white-box {
  background: #ffffff;
  padding: 25px;
  margin-bottom: 15px;
  box-shadow: 1px 1px 6px #ccc;
}

ul#side-menu a {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #efefef;
  opacity: 0.8;
  border-left: 3px solid transparent;
  text-decoration: none;
}

ul#side-menu a i {
  display: inline-block;
  min-width: 20px;
  opacity: 1;
}

.btn-primary {
  color: #fff;
  background-color: #86c7f3;
  border-color: #73b5e2;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1f6b9e;
  border-color: #0f4163;
}

.label-rouded.label-danger {
  background: rgb(134, 199, 243);
  color: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  font-size: 12px;
  line-height: 26px;
  border-radius: 100px;
  margin-top: -1px;
}

.logo {
  padding: 20px;
  max-width: 190px;
}

.btn {
  border-radius: 0;
  padding: 8px 20px;
}

ul#side-menu a.active {
  background-color: rgba(216, 216, 216, 0.1);
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  opacity: 1;
  border-left-color: #fff;
}

ul#side-menu a:hover {
  color: #fff;
  text-decoration: none;
  opacity: 1;
  background-color: rgba(216, 216, 216, 0.1);
  border-left-color: #fff;
}

ul#side-menu li.user-pro a {
  width: 92%;
  clear: both;
  overflow: hidden;
  margin: 10px 8px;
  color: #373a3c;
  text-align: left;
  font-weight: 400;
  font-size: 1.2rem;
  letter-spacing: normal;
  opacity: 1;
  padding: 5px;
  background: #fff;
  border-radius: 50px;
  height: 70px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16), 0 10px 20px 0 rgba(0, 0, 0, 0.19);
}

ul#side-menu li.user-pro a:hover,
ul#side-menu li.user-pro a:active {
  color: #3d5151 !important;
}

ul#side-menu li.user-pro a span {
  height: auto;
  max-width: 170px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul#side-menu li.user-pro a small {
  display: block;
  font-size: 80%;
  letter-spacing: normal;
  text-align: left;
  color: #8e9e9e;
}

ul#side-menu li a img.sidebar-icon {
  display: inline-block;
  width: 1.7rem;
  margin-right: 0.5rem;
  opacity: 1;
}

.sidebar-logo {
  position: absolute;
  bottom: 50px;
}

.logout {
  color: #e56b6b;
}

ol {
  padding-inline-start: 1rem;
}

ol li {
  margin-bottom: 0.5rem;
}

/********* 10. DASHBOARD STYLES **********/
/*****************************************/

.flotLegend {
  margin-bottom: 10px;
}

.flotLegend table tr td {
  padding-right: 5px;
}

.row-stat .panel .panel-heading {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.panel-icon {
  background: rgba(255, 255, 255, 0.9);
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 15px;
}

.panel-icon .fa {
  color: #000;
  font-size: 36px;
  padding: 12px 0 0 19px;
}

.panel-bluegreen .panel-icon .fa {
  color: #03c3c4;
}

.panel-violet .panel-icon .fa {
  color: #905dd1;
}

.panel-blue .panel-icon .fa {
  color: #e42b38;
}

.row-stat .md-title {
  opacity: 0.6;
  font-size: 12px;
  margin-bottom: 5px;
}

.row-stat h1 {
  margin-bottom: 0;
}

.row-stat hr {
  opacity: 0.2;
  margin: 20px 0;
  border-width: 1px;
  background: #fff;
}

.panel-icon .fa-users {
  padding: 12px 0 0 10px;
}

.panel-icon .fa-pencil {
  padding: 12px 0 0 17px;
}

.sublabel {
  font-size: 11px;
  display: block;
  margin-bottom: 3px;
}

.flotChart {
  width: 100%;
  height: 200px;
}

#bar-chart {
  height: 250px;
}

// Form Styles

/*Form Styles*/

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 19.5px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 400;
  color: #000;
  font-size: 14px;
}

input[type="search"] {
  box-sizing: border-box;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 13px;
  line-height: 1.538462;
  color: #555;
}

.form-control {
  display: block;
  width: 100% !important;
  height: 34px;
  padding: 6px 12px;
  font-size: 13px;
  line-height: 1.538462;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #90beb6;
  outline: 0;
  box-shadow: none;
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

label {
  font-weight: 400;
  font-size: 0.9em;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 34px;
  }

  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"],
  .input-group-sm>.input-group-btn>input[type="date"].btn,
  .input-group-sm>.input-group-btn>input[type="time"].btn,
  .input-group-sm>.input-group-btn>input[type="datetime-local"].btn,
  .input-group-sm>.input-group-btn>input[type="month"].btn,
  .input-group-sm>input[type="date"].form-control,
  .input-group-sm>input[type="date"].input-group-addon,
  .input-group-sm>input[type="time"].form-control,
  .input-group-sm>input[type="time"].input-group-addon,
  .input-group-sm>input[type="datetime-local"].form-control,
  .input-group-sm>input[type="datetime-local"].input-group-addon,
  .input-group-sm>input[type="month"].form-control,
  .input-group-sm>input[type="month"].input-group-addon,
  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm {
    line-height: 28px;
  }

  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"],
  .input-group-lg>.input-group-btn>input[type="date"].btn,
  .input-group-lg>.input-group-btn>input[type="time"].btn,
  .input-group-lg>.input-group-btn>input[type="datetime-local"].btn,
  .input-group-lg>.input-group-btn>input[type="month"].btn,
  .input-group-lg>input[type="date"].form-control,
  .input-group-lg>input[type="date"].input-group-addon,
  .input-group-lg>input[type="time"].form-control,
  .input-group-lg>input[type="time"].input-group-addon,
  .input-group-lg>input[type="datetime-local"].form-control,
  .input-group-lg>input[type="datetime-local"].input-group-addon,
  .input-group-lg>input[type="month"].form-control,
  .input-group-lg>input[type="month"].input-group-addon,
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg {
    line-height: 45px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkbox label,
.radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px\9;
}

.checkbox+.checkbox,
.radio+.radio {
  margin-top: -5px;
}

.checkbox-inline,
.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: 400;
  cursor: pointer;
}

.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline {
  margin-top: 0;
  margin-left: 10px;
}

fieldset[disabled] input[type="checkbox"],
fieldset[disabled] input[type="radio"],
input[type="checkbox"].disabled,
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="radio"][disabled] {
  cursor: not-allowed;
}

.checkbox-inline.disabled,
.radio-inline.disabled,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio-inline {
  cursor: not-allowed;
}

.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
  cursor: not-allowed;
}

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 33px;
}

.form-control-static.input-lg,
.form-control-static.input-sm,
.input-group-lg>.form-control-static.form-control,
.input-group-lg>.form-control-static.input-group-addon,
.input-group-lg>.input-group-btn>.form-control-static.btn,
.input-group-sm>.form-control-static.form-control,
.input-group-sm>.form-control-static.input-group-addon,
.input-group-sm>.input-group-btn>.form-control-static.btn {
  padding-left: 0;
  padding-right: 0;
}

.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn,
.input-sm {
  height: 28px;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 1px;
}

.input-group-sm>.input-group-btn>select.btn,
.input-group-sm>select.form-control,
.input-group-sm>select.input-group-addon,
select.input-sm {
  height: 28px;
  line-height: 28px;
}

.input-group-sm>.input-group-btn>select[multiple].btn,
.input-group-sm>.input-group-btn>textarea.btn,
.input-group-sm>select[multiple].form-control,
.input-group-sm>select[multiple].input-group-addon,
.input-group-sm>textarea.form-control,
.input-group-sm>textarea.input-group-addon,
select[multiple].input-sm,
textarea.input-sm {
  height: auto;
}

.form-group-sm .form-control {
  height: 28px;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 1px;
}

.form-group-sm select.form-control {
  height: 28px;
  line-height: 28px;
}

.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 28px;
  min-height: 31px;
  padding: 6px 10px;
  font-size: 11px;
  line-height: 1.5;
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn,
.input-lg {
  height: 45px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.333333;
  border-radius: 3px;
}

.input-group-lg>.input-group-btn>select.btn,
.input-group-lg>select.form-control,
.input-group-lg>select.input-group-addon,
select.input-lg {
  height: 45px;
  line-height: 45px;
}

.input-group-lg>.input-group-btn>select[multiple].btn,
.input-group-lg>.input-group-btn>textarea.btn,
.input-group-lg>select[multiple].form-control,
.input-group-lg>select[multiple].input-group-addon,
.input-group-lg>textarea.form-control,
.input-group-lg>textarea.input-group-addon,
select[multiple].input-lg,
textarea.input-lg {
  height: auto;
}

.form-group-lg .form-control {
  height: 45px;
  padding: 10px 16px;
  font-size: 17px;
  line-height: 1.333333;
  border-radius: 3px;
}

.form-group-lg select.form-control {
  height: 45px;
  line-height: 45px;
}

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 45px;
  min-height: 37px;
  padding: 11px 16px;
  font-size: 17px;
  line-height: 1.333333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.form-group-lg .form-control+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.input-group-lg>.form-control+.form-control-feedback,
.input-group-lg>.input-group-addon+.form-control-feedback,
.input-group-lg>.input-group-btn>.btn+.form-control-feedback,
.input-lg+.form-control-feedback {
  width: 45px;
  height: 45px;
  line-height: 45px;
}

.form-group-sm .form-control+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.input-group-sm>.form-control+.form-control-feedback,
.input-group-sm>.input-group-addon+.form-control-feedback,
.input-group-sm>.input-group-btn>.btn+.form-control-feedback,
.input-sm+.form-control-feedback {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
  color: #34a853;
}

.has-success .form-control {
  border-color: #34a853;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
  border-color: #288140;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #6fd38a;
}

.has-success .input-group-addon {
  color: #34a853;
  border-color: #34a853;
  background-color: #bdebc9;
}

.has-success .form-control-feedback {
  color: #34a853;
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
  color: #faa800;
}

.has-warning .form-control {
  border-color: #faa800;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
  border-color: #c78600;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffcb61;
}

.has-warning .input-group-addon {
  color: #faa800;
  border-color: #faa800;
  background-color: #ffedc7;
}

.has-warning .form-control-feedback {
  color: #faa800;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #e53935;
}

.has-error .form-control {
  border-color: #e53935;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
  border-color: #cd1e1a;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f1928f;
}

.has-error .input-group-addon {
  color: #e53935;
  border-color: #e53935;
  background-color: #fceaea;
}

.has-error .form-control-feedback {
  color: #e53935;
}

.has-feedback label~.form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only~.form-control-feedback {
  top: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }

  .form-inline .input-group .form-control,
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn {
    width: auto;
  }

  .form-inline .input-group>.form-control {
    width: 100%;
  }

  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .checkbox,
  .form-inline .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .checkbox label,
  .form-inline .radio label {
    padding-left: 0;
  }

  .form-inline .checkbox input[type="checkbox"],
  .form-inline .radio input[type="radio"] {
    position: relative;
    margin-left: 0;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-horizontal .checkbox,
.form-horizontal .radio {
  min-height: 27px;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}

.form-horizontal .form-group:after,
.form-horizontal .form-group:before {
  content: " ";
  display: table;
}

.form-horizontal .form-group:after {
  clear: both;
}

.form-control-feedback {
  font-size: 17px;
}

.input-pill {
  border-radius: 10em;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.form-wizard .fw-header {
  border-bottom: 1px solid #e5e5e5;
}

.form-wizard .nav-pills>li+li {
  margin-left: 0;
}

.form-wizard .nav-pills>li>a {
  background-color: #1d87e4;
  color: #fff;
  border-radius: 0;
  height: 50px;
  line-height: 50px;
  padding: 0 20px 0 30px;
}

.form-wizard .nav-pills>li>a .chevron {
  border: 25px solid transparent;
  border-left: 15px solid rgba(0, 0, 0, 0.15);
  border-right: 0;
  display: block;
  position: absolute;
  right: -15px;
  top: 0;
  z-index: 1;
}

.form-wizard .nav-pills>li>a .chevron:before {
  border: 25px solid transparent;
  border-left: 15px solid #1d87e4;
  border-right: 0;
  content: "";
  display: block;
  position: absolute;
  right: 1px;
  top: -25px;
}

.form-wizard .nav-pills>li.active>a,
.form-wizard .nav-pills>li.active>a:focus,
.form-wizard .nav-pills>li.active>a:hover {
  background-color: #1d87e4;
  color: #fff;
}

.form-wizard .nav-pills>li.active>a .chevron:before,
.form-wizard .nav-pills>li.active>a:focus .chevron:before,
.form-wizard .nav-pills>li.active>a:hover .chevron:before {
  border-left-color: #1d87e4;
}

.form-wizard .nav-pills>li.active~li>a,
.form-wizard .nav-pills>li.active~li>a:focus,
.form-wizard .nav-pills>li.active~li>a:hover {
  background-color: #fff;
  color: #1d87e4;
}

.form-wizard .nav-pills>li.active~li>a .chevron:before,
.form-wizard .nav-pills>li.active~li>a:focus .chevron:before,
.form-wizard .nav-pills>li.active~li>a:hover .chevron:before {
  border-left-color: #fff;
}

.form-wizard .fw-footer {
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  padding: 20px 30px;
}

.form-wizard .fw-footer .pager {
  margin: 0;
}

.form-wizard .progress {
  border-radius: 0;
  height: 5px;
  margin-bottom: 0;
  box-shadow: none;
}

.form-wizard .tab-content {
  padding: 30px;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 17px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 11px;
  }
}

.custom-control {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  margin-bottom: 0;
  min-height: 16px;
  min-width: 16px;
  padding-left: 25px;
  position: relative;
  vertical-align: middle;
}

.custom-control+.custom-control {
  margin-left: 10px;
}

.custom-controls-stacked .custom-control {
  display: block;
}

.custom-controls-stacked .custom-control+.custom-control {
  margin-left: 0;
  margin-top: 10px;
}

.custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.custom-control-input:active~.custom-control-indicator,
.custom-control-input:checked~.custom-control-indicator {
  border: none;
}

.custom-control-indicator {
  background-color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  display: block;
  height: 18px;
  left: 0;
  position: absolute;
  top: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 18px;
  z-index: 1;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  z-index: 1;
}

.custom-control-label::after {
  top: 2px;
}

.custom-control-label::before {
  top: 2px;
}

input[type="radio"]+span.custom-control-indicator {
  border-radius: 10px;
}

input[type="radio"]+span.custom-control-indicator+.custom-control-label:after {
  z-index: 99;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: middle;
}

.custom-control .custom-control-input:disabled~.custom-control-indicator {
  cursor: not-allowed;
}

.custom-control .custom-control-input:disabled:checked~.custom-control-indicator {
  background-color: #eee;
}

.custom-control-default .custom-control-input:checked~.custom-control-indicator {
  background-color: #777;
}

.custom-control-default .custom-control-input:active~.custom-control-indicator {
  background-color: #b7b7b7;
}

.custom-control-primary .custom-control-input:checked~.custom-control-indicator {
  background-color: #1d87e4;
}

.custom-control-primary .custom-control-input:active~.custom-control-indicator {
  background-color: #8fc3f2;
}

.custom-control-success .custom-control-input:checked~.custom-control-indicator {
  background-color: #34a853;
}

.custom-control-success .custom-control-input:active~.custom-control-indicator {
  background-color: #83d99a;
}

.custom-control-info .custom-control-input:checked~.custom-control-indicator {
  background-color: #7d57c1;
}

.custom-control-info .custom-control-input:active~.custom-control-indicator {
  background-color: #c5b4e3;
}

.custom-control-warning .custom-control-input:checked~.custom-control-indicator {
  background-color: #faa800;
}

.custom-control-warning .custom-control-input:active~.custom-control-indicator {
  background-color: #ffd47b;
}

.custom-control-danger .custom-control-input:checked~.custom-control-indicator {
  background-color: #e53935;
}

.custom-control-danger .custom-control-input:active~.custom-control-indicator {
  background-color: #f4a8a6;
}

.switch {
  display: inline-block;
  font-weight: 400;
  min-height: 18px;
  min-width: 40px;
  padding-left: 50px;
  position: relative;
}

.switch .s-input {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}

.switch .s-content {
  cursor: pointer;
  display: block;
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 40px;
}

.switch .s-track {
  background: #e5e5e5;
  border-radius: 8px;
  display: block;
  height: 16px;
  position: relative;
}

.switch .s-input:checked+.s-content .s-track {
  background: #e5e5e5;
}

.switch .s-handle {
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  display: block;
  height: 18px;
  left: 0;
  position: absolute;
  top: -1px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 18px;
  z-index: 1;
}

.switch .s-input:checked+.s-content .s-handle {
  left: 25px;
}

.switch.switch-primary .s-input:checked+.s-content .s-handle {
  background: #1d87e4;
}

.switch.switch-success .s-input:checked+.s-content .s-handle {
  background: #34a853;
}

.switch.switch-info .s-input:checked+.s-content .s-handle {
  background: #7d57c1;
}

.switch.switch-warning .s-input:checked+.s-content .s-handle {
  background: #faa800;
}

.switch.switch-danger .s-input:checked+.s-content .s-handle {
  background: #e53935;
}

.switch .s-desc {
  display: block;
  line-height: 18px;
}

.switches-stacked .switch {
  display: block;
  margin-bottom: 15px;
}

.switches-stacked .switch:last-child {
  margin-bottom: 0;
}

.file-upload-btn {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.file-upload-input {
  bottom: 0;
  cursor: inherit;
  font-size: 1000px;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
}

.form-control {
  height: 42px;
  font-size: 14px;
}

span.input-group-btn {
  background: #ccc;
}

/** Buttons and custom generic styles ***/
.spenn-btn {
  padding: 10px 20px !important;
  color: #ffffff !important;
  border-radius: 35.5px !important;
  background: linear-gradient(to left, #539168 0%, #227e6d 60%, #207d6d 100%);
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16), 0 10px 20px 0 rgba(0, 0, 0, 0.19) !important;
  border: 0;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
}

// .spenn-btn:disabled{
//   background:gray !important;
//   box-shadow: 0 6px 6px 0 rgba(0,0,0,0.16), 0 10px 20px 0 rgba(0,0,0,0.19) !important;
//   border: 0;
//   font-size: 1rem !important;
//   font-weight: 400 !important;
//   line-height: 1.5 !important;
// }

.spenn-btn-white {
  color: #217e6e !important;
  background: #ffffff;
  border: 1px solid #217e6e !important;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 10%), 0 2px 8px 0 rgba(0, 0, 0, 18%) !important;
  font-weight: 500 !important;
}

.spenn-btn-red {
  color: #e56b6b !important;
  background: #ffffff;
  border: 1px solid #e56b6b;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.18);
}

.btn-close:focus {
  box-shadow: none;
}

.btn-square {
  border-radius: 5px !important;
  font-size: 0.9rem !important;
  padding: 12px 20px !important;
}

.btn-danger {
  background: #e56b6b !important;
  border-color: #e56b6b !important;
}

.btn-no-shadow {
  box-shadow: none !important;
}

.spenn-btn img {
  width: 26px;
  height: auto;
  margin-right: 10px;
}

.btn-transparent {
  color: #217e6e;
  padding: 0;
  background: transparent;
  font-size: 16px;
}

.btn-transparent:hover,
.btn-transparent:active,
.btn-transparent:focus {
  color: #217e6e;
}

.btn-transparent img {
  width: 32px;
  height: auto;
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}

.table td {
  font-size: 0.9em;
  color: #8e9e9e;
}

.table th {
  font-size: 0.9em;
  color: #31394d;
}

.table td a {
  color: #748aa1;
  font-weight: 500;
}

.table thead th {
  border-bottom-width: 0;
}

.table th,
.table td {
  border-top-width: 0;
  padding: 0.5rem 0.75rem;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f7f7;
}

.modal .table td {
  padding: 0.3rem 0.75rem;
}

.page-title {
  font-weight: 400;
  color: #31394d;
}

.custom-input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-weight: 300;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.panel-date {
  font-size: 0.6em;
  font-weight: 300;
}

.panel.panel-default {
  box-shadow: none;
  background: transparent;
}

.account-balance {
  font-size: 2.5em;
  line-height: 1em;
  font-weight: 500;
  color: #31846b;
}

.account-balance-black {
  font-size: 2.5em;
  line-height: 1em;
  font-weight: 400;
  color: black;
}

.account-balance-currency {
  font-size: 1.3em;
  line-height: 1em;
  font-weight: 400;
}

.text-secondary {
  color: #8e9e9e !important;
}

.text-spenn {
  color: #31846b !important;
}

.text-spenn-light {
  color: #217e6e !important;
}

.text-default {
  color: #373a3c !important;
}

.text-danger {
  color: #e56b6b !important;
}

.text-darkder {
  color: #09422a !important;
}

.text-normal {
  font-weight: 400;
}

.text-light {
  font-weight: 300;
}

.text-bold {
  font-weight: 500;
}

.font-small {
  font-size: 0.9rem;
}

.spenn-my-2 {
  margin-top: 0.4em !important;
  margin-bottom: 0.4em !important;
}

.spenn-alert {
  background: #edf2f2;
  color: #000;
  font-weight: 400;
  font-size: 0.9em;
  text-align: center;
  padding: 1.25em 1em;
  border-radius: 0.25em;
  margin-bottom: 1.25em;
}

.alert {
  font-weight: 400;
  font-size: 0.9em;
}

.dropdown-menu {
  padding: 0;
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.dropdown-item {
  color: #333;
  font-size: 0.9rem !important;
  padding: 8px 20px !important;
}

.dropdown-divider {
  margin: 0;
  border-top: 1px solid #cccccc;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f5f7f7;
  color: #333;
}

/** Overriding bootstrap styles **/
.nav-tabs {
  border-bottom: 2px solid #d3e0e0;
}

.nav-link:hover,
.nav-tabs .nav-link.active {
  color: #217e6e;
}

.nav-tabs .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}

// .nav-tabs .nav-link.active {
//   border-width: 3px;
//   border-color: #4e8f68;
//   font-weight: 500;
// }

.tab-pane {
  margin-top: 2rem;
}

.page-bottom-fixed {
  position: fixed;
  width: 100%;
  padding-right: 350px;
  padding-left: 50px;
  margin-left: -50px;
  bottom: 0;
  z-index: 10;
  background: #fff;
}

.list-group-item {
  padding: 0;
  border: 0;
  margin: 0.5em 0;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  position: relative;
}

.list-group-item {
  font-size: 0.9em;
  font-weight: 300;
  margin-bottom: 15px;
}

.list-group-item h6 {
  font-weight: 400;
}

.payment-list-amount {
  font-size: 1.2em;
  line-height: 46px;
}

.list-group-item-action {
  cursor: pointer;
  width: 100%;
}

.payments-list-item-date {
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 20px !important;
}

.payments-list-item-icon {
  background: #edf2f2;
  border-radius: 100px;
  padding: 12px;
  width: 48px;
  height: 48px;
}

.payments-list-item-icon img {
  width: 24px;
  height: auto;
}

.payments-list-item-box .list-group-item {
  padding: 12px;
  margin-bottom: 20px !important;
  border-radius: 5px;
}

.list-group-item-action:hover,
.list-group-item-action:focus,
.list-group-item-action:active {
  background: inherit;
  box-shadow: 3px 0 8px 0 rgba(0, 0, 0, 0.1), 0 10px 15px 0 rgba(0, 0, 0, 0.13);
  transition: box-shadow 0.3s;
}

.list-group-item-action:hover>.payments-list-item-amount {
  color: #373a3c !important;
}

.payments-list-item-body {
  line-height: 1.5rem;
}

.payments-list-item-body .text-secondary {
  font-size: 0.9rem;
  font-weight: 400;
}

.payments-list-item-amount {
  font-weight: 400;
  font-size: 1rem;
  white-space: nowrap;
  padding-left: 6px;
}

.display-table {
  display: table;
}

.display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.tab-pane {
  margin-top: 3rem;
}

/** Spin image **/
.spin-img {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**** Modal styles ***/
.modal-header {
  padding: 14px 24px;
}

.modal-content {
  border-radius: 10px;
}

.modal-body {
  padding: 1.5rem;
}

// .modal-dialog {
//   max-width: 600px;
// }

// .modal-small .modal-dialog {
//   max-width: 500px;
// }

.modal-text {
  font-size: 0.9rem;
}

/***** Icons *****/
.icon-circle-large {
  width: 96px;
  height: 96px;
  border-radius: 96px;
  background-color: #edf2f2;
  text-align: center;
  padding: 24px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.icon-circle-large img {
  vertical-align: middle;
  width: 48px;
  height: auto;
}

.modal-property {
  display: block;
  padding: 10px 0;
}

.modal-body-inner {
  font-size: 0.9em;
}

.progress-bar {
  background-color: #217e6e;
}

.cursor-pointer {
  cursor: pointer;
}

.request-money-list table thead tr td {
  border-bottom: 1px solid #8e9e9e !important;
}

.request-money-list table thead tr td h6 {
  font-weight: 400;
  font-size: 1rem;
}

.request-money-list table tbody tr td {
  vertical-align: middle;
  padding: 0.75rem;
  line-height: 3rem;
}

.request-money-list table tbody tr td span {
  font-size: 1rem;
}

.btn-square-small {
  border-radius: 5px !important;
  font-size: 0.9rem !important;
  padding: 4px 20px !important;
}

.vertical-1 {
  margin: 1% 0 1% 0;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0 !important;
}

.mat-dialog-content {
  border-top: 1px solid #ccc;
}

.mat-custom-button {
  min-width: 130px !important;
  line-height: 26px !important;
}

.text-decoration-none {
  text-decoration: none;
}

.mat-button-sm {
  line-height: 1.5 !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  min-width: 115px !important;
}

.gray-button.mat-button {
  color: #5c5c5c !important;
}

.mr-20 {
  margin-right: 15px !important;
}

.mat-flat-button.mat-primary {
  background-color: #a6fbc9 !important;
  font-size: 1rem;
}

.mat-flat-button {
  color: #08432a !important;
}

.mat-raised-buttons {
  border-color: rgba(0, 0, 0, 0.6) !important;
  border: 1.8px solid currentColor !important;
}

.button.mat-primary {
  border: solid 1.2px #1b5e20;
}

.text-normal {
  font-weight: 400;
}

*,
:after,
:before {
  box-sizing: border-box;
}

.d-block {
  display: block !important;
}

.text-center {
  text-align: center !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.text-end {
  text-align: right !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.cdk-overlay-pane {
  .diloge-header {
    padding-bottom: 10px;
  }

  .mat-dialog-container {

    // height: auto;
    // border-radius: calc(0.6rem - 1px);
    // padding: 0px;
    // max-height: calc(100vh - 50px);
    .mat-dialog-actions {
      margin-bottom: 10px;
      padding: 0;
      line-height: normal;
      min-height: auto;
    }

    .mat-dialog-content {
      // max-height: none;
      // margin: 0px;
      padding: 24px 24px 6px;
      max-height: calc(100vh - 100px);

      .btn-default {
        width: 100%;
        display: inline-block;
        text-align: center;
      }

      table {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.full-width-dialog {
  max-width: 500px !important;
  min-width: 500px !important;
}

.edit-full-width-dialog {
  max-width: 600px !important;
  min-width: 600px !important;
}

.create-payment-full-width-dialog,
.duplicateList-full-width-dialog {
  max-width: 600px !important;
  min-width: 600px !important;
}

button {
  border: none;
  background: transparent;
}

button:focus {
  box-shadow: none;
  outline: none;
}

h6 {
  font-size: 1rem;
  margin: 0px;
  font-weight: 400;
}

.close_btn {
  cursor: pointer;
  color: #8e9e9e !important;
  line-height: 0;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
  // margin-top: 3rem;
}

.spenn-pagination {
  font-size: 0.9em;
  color: #aab2c0;
  width: 100%;
}

.spenn-pagination .spenn-pagination-number {
  color: #3f495b;
}

.modal-none {
  display: none;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.custom-file,
.custom-file-input {
  position: relative;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}

.custom-file-input {
  z-index: 2;
  margin: 0;
  opacity: 0;
}

input[type="file"] {
  display: block;
}

.custom-file-label,
.custom-file-label:after {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
}

.custom-file-label {
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label:after {
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem + 2px) -inner;
  content: "Browse";
  background: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.text-right {
  text-align: right !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-md-5 {
  margin-right: 3rem !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.add-paymentlist-item .btn-search {
  position: absolute;
  right: 0;
  border-radius: 2px !important;
  font-size: 14px;
  height: 42px;
  border: 1px solid #ccc;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.h5,
h5 {
  font-size: 1.25rem;
}

.form-group {
  margin-bottom: 15px;
}

.text-input {
  position: absolute;
  top: 0;
}

.table {
  width: 100%;
  caption-side: bottom;
  border-collapse: collapse;
}

// .table>thead {
//   border-bottom: 1px solid #217E6E;
// }

.table td,
.table th {
  border-top-width: 0;
  padding: 0.5rem 0.75rem;
  text-align: left;
  list-style: 1.5rem;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border: 0 solid;
  border-color: inherit;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: #217e6e;
}

// .table td {
//   border-bottom: solid 1px #8e9e9e;
// }

button:disabled,
a:disabled {
  pointer-events: none;
  opacity: 0.65;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.table> :not(caption)>*>* {
  padding: 0.5rem;
  background-color: transparent;
  border-bottom-width: 1px;
  border-color: #dee2e6 !important;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-35 {
  margin-bottom: 35px;
}

.font-400 {
  font-weight: 400 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#importPaymentListStatModal {
  .modal-content {
    border-radius: 0;
    border: none;
  }
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}


.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

#paymentPayslipModal ::ng-deep .mat-dialog-content {
  border-top: 0 !important;
}

.button-rounded-border {
  padding: 5px 20px !important;
  border-radius: 25px !important;
  min-width: 100px !important;
  line-height: 30px !important;
}

.mr-20 {
  margin-right: 15px !important;
}

.red-text {
  color: red;
}

.bank-image-big {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #b5c6bf;

  img {
    width: 30px !important;
    height: 30px !important;
  }
}

.btn:hover {
  color: #fff;
}

.btn-search:hover {
  color: #000;
}

.modal-show .mat-dialog-container,
.create-payment-full-width-dialog .mat-dialog-container,
.edit-full-width-dialog .mat-dialog-container,
.duplicateList-full-width-dialog .mat-dialog-container,
.full-width-dialog .mat-dialog-container {
  padding: 12px 24px !important;
  background: white !important;
}

.modal {
  background-color: rgba(10, 10, 10, 0.45);
}

.modal-backdrop {
  display: none;


}

.mat-sidenav {
  border-right: 0px solid #fff !important;
}

.dialog-wrapper .mat-dialog-container {
  padding: 0px !important;
  border-radius: 20px;

  .mat-card {
    background-color: #f7fafe;
  }
}

.pad-heder {
  padding: 12px 20px 10px;
  border-bottom: solid 1px rgba(0, 0, 0, .4);
}

.height-50 {
  max-height: 50px;
}

.border-button{
  border: solid 1px #000;
  border-radius: 4px;
  padding: 15px 60px !important;
}

.mt-30-20{
  margin-top: 30px;
  margin-bottom: 20px;
}

.box-shadow{
  box-shadow: 0 3px 6px rgba(0,0,0,.20);
}
#moveFundsForm{
  .input-wrapper{
    border: solid 1px #000;
    border-radius: 2px;
    padding: 10px;
  }
}

.pad-20{
  margin: 20px;
}

.listborder > div{
  padding:10px;
  border-bottom: solid 1px rgba(0, 0, 0, .4);
  & label{
    margin: 0;
  }
}

.back{
  margin-bottom: 20px;
}